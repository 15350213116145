import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

const PageNotFound = () => {
  return (
    <Layout>
      <Seo title="404 Страница не найдена" />
      <div className="relative flex flex-nowrap text-center items-center justify-center h-screen w-full bg-red-800">
        <div className="z-10 container mx-auto">
          <div className="font-sans text-xl md:text-6xl text-red-900 w-full z-10">
            Страница не найдена
          </div>
          <div className="font-sans text-4xl md:text-7xl text-white w-full font-extrabold z-10 pb-10">
            Ошибка 404
          </div>
          <div className="font-sans text-md md:text-4xl w-full z-10 text-white">
            <Link to="/" className="p-5 bg-red-900 rounded-lg">
              Вернуться на главную страницу
            </Link>
          </div>
        </div>

        <div className="absolute bg-red-300 w-1/2 h-1/2 rounded-3xl transform rotate-3 shadow"></div>
        <div className="absolute bg-red-200 w-1/2 h-1/2 rounded-3xl transform -rotate-6 shadow"></div>
        <div className="absolute bg-red-400 w-1/2 h-1/2 rounded-3xl transform -rotate-3 z-0 shadow"></div>
      </div>
    </Layout>
  )
}

export default PageNotFound
